<template>
  <div class="service-list">
    <ul class="service-list__items">
      <li
        v-for="card in serviceCards"
        :key="card"
        class="service-list__item"
      >
        <component
          :is="card"
          @click="onCardClick"
        />
      </li>
    </ul>

    <success-modal>
      <template #title>
        Спасибо, Ваша заявка отправлена
      </template>
    </success-modal>
  </div>
</template>

<script>
import * as Cards from '@/components/Services/Cards'
import SuccessModal from '@/components/Modals/SuccessModal.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('profile')

export default {
  components: {
    SuccessModal,
    ...Cards
  },

  computed: {
    serviceCards () {
      return [
        'create-shop',
        'superstore',
        'shop-decoration',
        'rich-content',
        'pricing',
        'marketing',
        'logistics',
        'analytics',
        'contact-center'
      ]
    }
  },

  methods: {
    ...mapActions(['sendServiceForm']),

    async onCardClick (name) {
      this.$metrics.hit('mmp_services_consultation')
      try {
        this.isLoading = true

        await this.sendServiceForm({ form_name: name })

        this.$modal.show('success-modal')
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .service-list
    &__items
      display grid
      grid-template-columns repeat(auto-fit, minmax(315px, 1fr))
      grid-template-rows auto
      column-gap 12px
      row-gap 36px
      list-style none
</style>
