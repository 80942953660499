<template>
  <service-card
    :bullets="bullets"
    image="pricing"
    @click="$emit('click', name)"
  >
    <template #title>
      {{ name }}
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'
import { pricingBullets } from '@/constants/services'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Ценообразование',
      bullets: pricingBullets
    }
  }
}
</script>
