<template>
  <modal
    :name="modalName"
    height="auto"
  >
    <div class="modal-body">
      <close-button @click="$modal.hide(modalName)" />

      <h2 class="modal-body__heading">
        <slot name="title" />
      </h2>

      <p class="modal-body__text">
        <slot />
      </p>

      <x-btn
        block
        @click="$modal.hide(modalName)"
      >
        <slot name="btnText">
          Замечательно
        </slot>
      </x-btn>
    </div>
  </modal>
</template>

<script>
import CloseButton from '@/components/Interface/CloseButton.vue'

export default {
  components: { CloseButton },

  data () {
    return {
      modalName: 'success-modal'
    }
  }
}
</script>

<style lang="stylus" scoped>
  .modal-body
    max-width 350px
    margin 0 auto
    padding 24px
    text-align center

    &__image
      display block
      max-width 88px
      margin 0 auto 10px

    &__heading
      margin-bottom 10px
      font-weight $font-weights.bold
      font-size 24px

    &__text
      margin-bottom 20px
      font-size 14px
</style>
