<template>
  <service-card
    :bullets="bullets"
    image="analytics"
    @click="$emit('click', name)"
  >
    <template #title>
      {{ name }}
    </template>

    <template #info>
      Проведём глубинный анализ работы вашего магазина, найдём точки роста и поможем масштабировать процессы,
      расскажем, на что стоит обратить внимание и как достичь наилучшего результата
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'
import { analyticsBullets } from '@/constants/services'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Аналитика и отчетность',
      bullets: analyticsBullets
    }
  }
}
</script>
