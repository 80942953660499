<template>
  <service-card
    :bullets="bullets"
    image="marketing"
    @click="$emit('click', name)"
  >
    <template #title>
      {{ name }}
    </template>

    <template #info>
      Подготовим и внедрим маркетинговую стратегию, учитывающую все особенности вашего бренда.
      Подберём и максимально эффективно применим комплекс digital-инструментов
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'
import { marketingBullets } from '@/constants/services'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Маркетинг: продвижение в ТОП',
      bullets: marketingBullets
    }
  }
}
</script>
