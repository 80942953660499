<template>
  <service-card
    image="rich-content"
    @click="$emit('click', name)"
  >
    <template #title>
      {{ name }}
    </template>

    <template #text>
      Rich-контент - маркетинговый инструмент, позволяющий  добавить в описание на карточке товара мультимедийные элементы,
      такие как изображения 360° и видео.
    </template>

    <template #info>
      Rich-контент повышает интерес потенциальных покупателей к товарам.
      С его помощью можно выделить свой товар среди конкурентов, поднять позиции магазина в поисковой выдаче,
      акцентировать внимание на отдельных характеристиках и достоинствах товара.
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Rich-контент'
    }
  }
}
</script>
