export const createShopBullets = Object.freeze([
  'Регистрация и настройка кабинета продавца',
  'Создание карточек товара',
  'Оформление витрины магазина',
  'Выбор логистической схемы',
  'Подключение платёжной системы',
  'Обучение работе с площадкой'
])

export const superstoreBullets = Object.freeze([
  'Размещение карточек товаров на платформах',
  'Внутреннее и внешнее продвижение',
  'Хранение и доставка до конечного пользователя'
])

export const shopDecorationBullets = Object.freeze([
  'Фото и видео товаров',
  'Тексты',
  'Дизайн витрины',
  'Рекламные баннеры',
  'Подготовка товарного фида',
  'Заливка карточек на маркетплейс'
])

export const pricingBullets = Object.freeze([
  'Проанализируем вашу товарную нишу и предложения конкурентов',
  'Найдём товары-аналоги и подготовим стратегию ценообразования, включающую требования рынка, комиссии и спецпредложения площадок',
  'Поможем контролировать и поддерживать актуальность цен для всей линейки ваших товаров'
])

export const marketingBullets = Object.freeze([
  'Акции и промо',
  'Контекстная и таргетированная реклама',
  'SEO-оптимизация',
  'Коллаборации с блогерами',
  'Прямые эфиры в Instagram'
])

export const logisticsBullets = Object.freeze([
  'Предоставим услугу складского хранения и учёта',
  'Упакуем товар под требования каждой площадки',
  'Поможем управлять остатками и доставим заказ вашему конечному покупателю'
])

export const analyticsBullets = Object.freeze([
  'Аналитика продаж',
  'Аналитика конкурентов',
  'Аналитика оборачиваемости',
  'Аналитика трендов'
])

export const contactBullets = Object.freeze([
  'Проконсультируем ваших покупателей, обработаем звонки и чаты',
  'Организуем выделенную линию поддержки или перенаправим звонки по нужному адресу'
])
