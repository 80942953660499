<template>
  <service-card
    :bullets="bullets"
    image="superstore"
    class="superstore"
    @click="$emit('click', name)"
  >
    <template #text>
      <div class="superstore__text">
        &#9889;&#9889;
        <i>Экспресс размещение</i>
        &#9889;&#9889;
      </div>
    </template>
    <template #title>
      {{ name }}
    </template>

    <template #info>
      Суперстор —  универмаг на маркетплейсе, объединяющий товары разных производителей и категорий.
      Вам не нужно создавать свой магазин,
      вы просто ставите товары на виртуальную полку уже существующего универмага — это быстрее, проще и дешевле.
      Комиссия за продажу товаров в суперсторе с продавца при этом не взимается
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'
import { superstoreBullets } from '@/constants/services'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Размещение в Суперсторе',
      bullets: superstoreBullets
    }
  }
}
</script>

<style lang="stylus" scoped>
  .superstore
    &__text
      color #f88600
      margin-bottom 10px
</style>
