<script>
export default {
  functional: true,
  render (createElement, { slots }) {
    const items = slots().default.map(s => createElement('li', {
      class: 'bullet-list__item'
    },
    s.children[0].text ?? s.text))

    const ul = createElement('ul', {
      class: 'bullet-list'
    },
    items
    )

    return ul
  }
}
</script>

<style lang="stylus" scoped>
  .bullet-list
    margin 0
    padding 0
    list-style none
    font-size 14px
    color #3d3d48

    &__item
      position relative
      margin-bottom 8px
      padding-left 32px

      &:before
        content ""
        position absolute
        top 50%
        left 0
        width 24px
        height 24px
        background-image url('~@/assets/images/svg/bullet.svg')
        transform translateY(-50%)
</style>
