<template>
  <service-card
    :bullets="bullets"
    image="shop-decoration"
    @click="$emit('click', name)"
  >
    <template #title>
      {{ name }}
    </template>
  </service-card>
</template>

<script>
import ServiceCard from '@/components/Common/ServiceCard.vue'
import { shopDecorationBullets } from '@/constants/services'

export default {
  components: {
    ServiceCard
  },

  data () {
    return {
      name: 'Оформление магазина',
      bullets: shopDecorationBullets
    }
  }
}
</script>
