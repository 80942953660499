<template>
  <x-card class="service-card">
    <div class="service-card__content">
      <heading level="3">
        <slot name="title" />

        <tooltip
          v-if="$slots.info"
          icon="info-fill"
          class="service-card__info"
        >
          <template #popover>
            <slot name="info" />
          </template>
        </tooltip>
      </heading>

      <span class="service-card__text">
        <slot name="text" />
      </span>

      <bullet-list v-if="bullets.length">
        <div
          v-for="bullet in bullets"
          :key="bullet"
          class="service-card__bullet"
        >
          {{ bullet }}
        </div>
      </bullet-list>

      <slot name="button">
        <x-btn
          class="service-card__button"
          @click="$emit('click')"
        >
          Заказать консультацию
        </x-btn>
      </slot>
    </div>

    <img
      v-if="image"
      class="service-card__thumbnail"
      :src="imagePath"
    >
  </x-card>
</template>

<script>
import Heading from '@/components/Common/Heading.vue'
import BulletList from '@/components/Common/BulletList.vue'
import Tooltip from '@/components/Common/Tooltip.vue'

export default {
  components: {
    Heading,
    Tooltip,
    BulletList
  },

  props: {
    image: {
      type: String,
      default: ''
    },
    bullets: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    imagePath () {
      return require(`@/assets/images/services/${this.image}.svg`)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .service-card
    display flex
    width 100%
    height 100%
    overflow hidden
    padding-right 0

    &__info
      display inline
      vertical-align middle

      >>> .svg-icon--info-fill
        color #cacad3

    &__thumbnail
      height 300px
      width 225px
      align-self flex-end
      margin-left auto

    &__content
      display flex
      flex-direction column
      min-height 290px

    &__bullet
      margin-bottom 11px

    &__button
      justify-self flex-end
      margin-top auto

    &__text
      color #7B7B85
</style>
